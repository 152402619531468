import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";

import { AppProps } from "~/types";
import { Menu } from "../app/Menu";
import NextHeadSeo from "next-head-seo";
import { useConfigContext } from "~/providers/ConfigContextProvider";
import { UserTracking } from "../app/UserTracking";
import { NewsletterPopup } from "../app/NewsletterPopup";

const Login = dynamic(() => import("../app/Login"));

export const LayoutIntroSlideshow = ({ props, children }: AppProps) => {
  const config = useConfigContext();

  const [isAuthenticated, setAuthenticated] = useState<undefined | boolean>(
    undefined
  );

  const cookieName = `pwd-${props?.data?.slug}`;
  
  useEffect(() => {
    if (!props?.data?.password?.trim()) return;
    
    try {
      setAuthenticated(Cookies.get(cookieName) === "yes");
    } catch (e) {
      setAuthenticated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data?.password]);

  if (props?.data?.password?.trim() && !isAuthenticated)
    return <Login cookieName={cookieName} password={props?.data?.password?.trim()} />;

  return (
    <>
      <NextHeadSeo
        title={`${config.appTitle}`}
        og={{
          image: `${config.baseUrl}/img/ikon-poster.jpg`,
          type: "article",
          siteName: `${config.appTitle}`,
        }}
        twitter={{
          card: "summary_large_image",
        }}
      />
      <UserTracking />
      <NewsletterPopup />
      {children}
      <Menu />
    </>
  );
};
export default LayoutIntroSlideshow;
