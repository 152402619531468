import React, { useState, useRef, MouseEventHandler, useEffect } from "react";
import styled from "styled-components";
import Link from "next/link";
import useIsMounted from "~/hooks/useIsMounted";
import PageMargins from "../styled/PageMargins";
import { Heading } from "./Heading";
import Button from "../styled/Button";
import { SvgBackground } from "./SvgBackground";

import {
  HeaderTwoCol,
  HeaderTwoColCol,
} from "~/components/styled/HeaderTwoCol";
import DisplayAbove from "../styled/DisplayAbove";
import { primaryInput } from "detect-it";

const uA =
  typeof navigator !== "undefined" ? navigator.userAgent.toLowerCase() : "";

const isChrome = uA.indexOf("chrome") !== -1;
const isFirefox = uA.indexOf("firefox") !== -1;
const isSafari =
  (uA.indexOf("safari") !== -1 ||
    uA.indexOf("applewebkit") !== -1 ||
    uA.indexOf("ipad") !== -1 ||
    uA.indexOf("iphone") !== -1) &&
  !isChrome;

const StyledScroller = styled.div<{
  spaceHeight: any;
  spaceTop?: number;
  hasBottomBorder: boolean;
}>`
  width: 100%;
  overflow: hidden;
  overscroll-behavior-x: contain;
  position: relative;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        height: auto;
        ${
          props.spaceTop
            ? `margin-top: ${props.theme.spacePx(breakpoint, props.spaceTop)};`
            : ""
        }

        @media (any-pointer: fine) {
          height: ${
            typeof props.spaceHeight[breakpoint] === "string"
              ? props.spaceHeight[breakpoint]
              : `calc(${(Array.isArray(props.spaceHeight[breakpoint])
                  ? props.spaceHeight[breakpoint]
                  : [props.spaceHeight[breakpoint]]
                )
                  .map((h: number) => props.theme.spacePx(breakpoint, h))
                  .join(" + ")} + 15px)`
          };
        }
        
        margin: 0 0 ${props.theme.spacePx(breakpoint, 5)} 0;          
        
        ${
          props.hasBottomBorder
            ? `
          &::after {
            display: block;
            content:"";
            position:absolute;
            bottom: 0px;
            z-index: 2;
            left: ${props.theme.marginPx(breakpoint)};
            width: calc(100% - (2 * ${props.theme.marginPx(breakpoint)}));
            border-top: 1px solid #000;
          }
        `
            : ""
        };
      `;
    })}
`;

const StyledScrollerScrollable = styled.div<{
  isDragging: boolean;
  isIOS: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: ${({ isDragging }) => (isDragging ? "auto" : "smooth")};

  cursor: ${({ isDragging }) =>
    isDragging ? "grabbing !important" : "default"};
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scrollbar-color: #555 white;

  overscroll-behavior-x: contain;

  & > article {
    flex-shrink: 0;
    flex-grow: 0;

    user-select: ${({ isDragging }) => (isDragging ? "none" : "auto")};
  }
  & a {
    user-select: none;
    -webkit-user-drag: none;
    cursor: ${({ isDragging }) =>
      isDragging ? "grabbing !important" : "pointer"};
  }

  &::-webkit-scrollbar {
    height: 15px;
    width: 100%;
    display: ${({ isIOS }) => (isIOS ? "none" : "block")};
  }

  &::-webkit-scrollbar-track {
    border-top: 7px solid var(--ikon-bg-color, #fff);
    border-bottom: 7px solid var(--ikon-bg-color, #fff);
    background-color: #000;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000;
    border-top: 5px solid var(--ikon-bg-color, #fff);
    border-bottom: 5px solid var(--ikon-bg-color, #fff);
    border-radius: 0;
    cursor: pointer;
    display: block;
  }

  @media (any-pointer: fine) {
    &:hover {
      &::-webkit-scrollbar-thumb {
        border-top: 3px solid var(--ikon-bg-color, #fff);
        border-bottom: 3px solid var(--ikon-bg-color, #fff);
      }
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        scroll-padding-left: ${props.theme.marginPx(breakpoint)};
        padding: 0 0 ${(props.theme.space(breakpoint, 7) - 7).toFixed(0)}px 0; 
        &::-webkit-scrollbar-track {
          margin: 0 ${props.theme.marginPx(breakpoint)}; 
        }
      `;
    })}
`;
const Border = styled.div`
  border-top: 1px solid #000;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        margin: 0 ${props.theme.marginPx(breakpoint)};          
      `;
    })}
`;

const Spacer = styled.article`
  height: 20px;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        width: ${props.theme.marginPx(breakpoint)}; 
        
      `;
    })}
`;

const NavButton = styled(Button)<{
  rotateButton?: boolean;
  fontStyle: string;
}>`
  transform: ${({ rotateButton }) => (rotateButton ? "rotate(180deg)" : "")};

  transition: filter 0.5s;
  pointer-events: all;

  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    touch-action: none;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        width: ${props.theme.spacePx(
          breakpoint,
          props.fontStyle === "bold" ? 7 : 8
        )};
        height: ${props.theme.spacePx(
          breakpoint,
          props.fontStyle === "bold" ? 7 : 8
        )};
      
        `;
    })}
`;

export const VerticalSlider = ({
  children,
  spaceHeight,
  spaceTop,
  header,
  hasNav,
  itemSelector = ":scope > article",
  moreLinkUrl,
  moreLinkLabel,
  fontStyle = "default",
}: {
  children: React.ReactNode;
  spaceHeight: any;
  spaceTop?: number;
  header?: string;
  hasNav?: boolean;
  itemSelector?: string;
  moreLinkUrl?: string;
  moreLinkLabel?: string;
  fontStyle?: string;
}) => {
  const isMounted = useIsMounted();

  const scrolledItemsRef = useRef<any>([]);
  const isDraggingRef = useRef(false);
  const dragStartXRef = useRef<number>(0);
  const mouseOverTimeCounterRef = useRef<number>(0);
  // const animationFrameStartRef = useRef<number | null>(0);
  const dragDisplacementRef = useRef<number>(0);
  const scrollerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  // const mouseXPositionRef = useRef(0);
  const dragDelayTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const animationFrameRef = useRef<ReturnType<
    typeof requestAnimationFrame
  > | null>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [navHasNext, setNavHasNext] = useState(true);
  const [navHasPrev, setNavHasPrev] = useState(false);

  const handleOnPointerUp = (immediately: boolean) => {
    if (!isMounted) return;

    if (immediately) {
      if (dragDelayTimeoutRef.current)
        clearTimeout(dragDelayTimeoutRef.current);
      setIsDragging(false);
      dragDisplacementRef.current = 0;
      isDraggingRef.current = false;
    } else {
      dragDelayTimeoutRef.current = setTimeout(() => {
        if (isMounted) {
          setIsDragging(false);
          dragDisplacementRef.current = 0;
          dragDelayTimeoutRef.current = null;
          isDraggingRef.current = false;
        }
      }, 60);
    }
  };

  const onMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!isMounted) return;
    dragDisplacementRef.current = 0;

    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
      mouseOverTimeCounterRef.current = 0;
    }

    dragStartXRef.current = event.clientX;
    isDraggingRef.current = true;
    setIsDragging(true);
  };

  const onMouseMove: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!isMounted) return;

    if (isDraggingRef.current) {
      const mouseX = event.clientX;

      const moveBy = dragStartXRef.current - mouseX;
      dragDisplacementRef.current += Math.abs(moveBy);

      scrollerRef.current.scrollLeft = scrollerRef.current.scrollLeft + moveBy;

      dragStartXRef.current = mouseX;
    }
  };

  const navigate = (index: number) => {
    if (typeof window === "undefined") return;
    if (!isMounted || !scrollerRef.current?.style) return;
    if (!scrolledItemsRef.current?.length) return;
    if (index < 0 && index >= scrolledItemsRef.current.length) return;


    scrollerRef.current.scrollTo({
      left:
        scrolledItemsRef.current[index].offsetLeft - scrolledItemsRef.current[0].offsetWidth
        ,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (scrollerRef.current) {
      scrolledItemsRef.current =
        scrollerRef.current.querySelectorAll(itemSelector);
      scrollerRef.current.style.scrollBehavior = "auto";
      scrollerRef.current.scrollLeft = 0;
      scrollerRef.current.style.scrollBehavior = "smooth";
      scrollerRef.current.style.overflowX = "none";

      setTimeout(() => {
        if (!scrollerRef.current) return;

        scrolledItemsRef.current =
          scrollerRef.current.querySelectorAll(itemSelector);
        scrollerRef.current.scrollLeft = 0;
        scrollerRef.current.dispatchEvent(new CustomEvent("scroll"));
      
        setTimeout(() => {
          if (!scrollerRef.current) return;
          scrollerRef.current.scrollLeft = 0;
          scrollerRef.current.style.overflowX = "scroll";
          scrollerRef.current.dispatchEvent(new CustomEvent("scroll"));          
        }, 800);
      }, 200);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {header && (
        <PageMargins spaceTop={spaceTop}>
          <HeaderTwoCol>
            <HeaderTwoColCol>
              <Heading
                heading={fontStyle === "bold" ? "h2" : "h3"}
                asTag
                space={8}
                inline
                html={header}
              />
              {moreLinkUrl && moreLinkLabel && (
                <Link href={moreLinkUrl} passHref>
                  <a>
                    <Heading
                      heading={fontStyle === "bold" ? "h3" : "h4"}
                      space={9}
                      inline
                      html={moreLinkLabel}
                    />
                  </a>
                </Link>
              )}
            </HeaderTwoColCol>

            {hasNav && (navHasPrev || navHasNext) && (
              <DisplayAbove breakpoint="tablet">
                <HeaderTwoColCol>
                  <NavButton
                    aria-label="Scroll to previous item"
                    fontStyle={fontStyle}
                    rotateButton
                    disabled={!navHasPrev}
                    onClick={() => {
                      if (!scrolledItemsRef.current?.length) return;

                      const prevIndex = [...scrolledItemsRef.current].reduce(
                        (i: number, item: any, index: number) => {
                          if (
                            item.offsetLeft < scrollerRef.current.scrollLeft &&
                            index < scrolledItemsRef.current.length - 1
                          ) {
                            return index;
                          }
                          return i;
                        },
                        0
                      );
                      navigate(prevIndex);
                    }}
                  >
                    <SvgBackground width="100%" height="100%" type="arrow" />
                  </NavButton>
                  <NavButton
                    aria-label="Scroll to next item"
                    fontStyle={fontStyle}
                    disabled={!navHasNext}
                    onClick={() => {
                      if (!scrolledItemsRef.current?.length) return;

                      const paddingLeft =
                        scrolledItemsRef.current[0].offsetWidth;

                      const nextIndex = [...scrolledItemsRef.current].reduce(
                        (i: number, item: any, index: number) => {
                          if (
                            item.offsetLeft >
                            scrollerRef.current.scrollLeft + paddingLeft
                          ) {
                            return i < 0 ? index : i;
                          }
                          return i;
                        },
                        -1
                      );

                      if (nextIndex > 0) {
                        navigate(nextIndex);
                      }
                    }}
                  >
                    <SvgBackground width="100%" height="100%" type="arrow" />
                  </NavButton>
                </HeaderTwoColCol>
              </DisplayAbove>
            )}
          </HeaderTwoCol>
        </PageMargins>
      )}
      <StyledScroller
        spaceHeight={spaceHeight}
        spaceTop={!header && spaceTop ? spaceTop : undefined}
        hasBottomBorder={(isSafari && primaryInput === "touch") || isFirefox}
      >
        <Border />
        <StyledScrollerScrollable
          isIOS={isSafari && primaryInput === "touch"}
          onScroll={() => {
            const paddingLeft = parseInt(
              getComputedStyle(scrollerRef.current).paddingLeft
            );

            if (scrollerRef.current.scrollLeft > paddingLeft) {
              if (!navHasPrev) {
                setNavHasPrev(true);
              }
            } else {
              if (navHasPrev) {
                setNavHasPrev(false);
              }
            }

            if (
              scrollerRef.current.scrollWidth > window.innerWidth &&
              scrollerRef.current.scrollLeft + window.innerWidth <
                scrollerRef.current.scrollWidth
            ) {
              if (!navHasNext) {
                setNavHasNext(true);
              } else {
              }
            } else {
              if (navHasNext) {
                setNavHasNext(false);
              }
            }
          }}
          ref={scrollerRef}
          isDragging={isDragging}
          onClickCapture={(event) => {
            if (dragDisplacementRef.current > 10) {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
          onMouseUp={(event) => {
            handleOnPointerUp(false);
          }}
          onMouseDown={onMouseDown}
          onMouseEnter={() => {
            handleOnPointerUp(true);
          }}
          onMouseMove={onMouseMove}
        >
          <Spacer />
          {children}
          <Spacer />
        </StyledScrollerScrollable>
      </StyledScroller>
    </>
  );
};

export default VerticalSlider;
